import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Gallery, Headline, Numbers, Story, Team } from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-W1G8LXHX5C']
    })
  ]
})
const analyticsa = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})
analytics.page()
analyticsa.page()

const AboutSideCover = () => {
  return (
    <Main>
      {/* <Box> */}
        {/* <Container>
          <Headline />
        </Container> */}
        {/* <Container paddingY={'0 !important'}>
          <Gallery />
        </Container> */}
        <Container maxWidth={'800px !important'}>
          <Numbers />
        </Container>
        {/* <Container maxWidth={'800px !important'}>
          <Divider />
        </Container>
        <Container>
          <Story />
        </Container>
        <Container maxWidth={'800px !important'}>
          <Divider />
        </Container>
        <Container>
          <Team />
        </Container>
      </Box> */}
    </Main>
  );
};

export default AboutSideCover;
